// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DomUtils from "../utils/DomUtils.bs.js";

function SimpleBackButton(Props) {
  return React.createElement("button", {
              className: "bg-gray-100 border border-gray-300 text-sm block px-3 py-1 rounded-full",
              onClick: (function (param) {
                  DomUtils.goBack(undefined);
                })
            }, React.createElement("i", {
                  className: "if i-arrow-left-regular if-fw inline-block me-1"
                }), "back");
}

var make = SimpleBackButton;

export {
  make ,
}
/* react Not a pure module */
